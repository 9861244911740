<template>
<div id="app" :style="{ backgroundImage: 'url(' + bgImage + ')' }" v-cloak>
  <!-- MODAL -->
  <div
    :class="modal ? 'modal-container activated' : 'modal-container'"
    id="login-modal"
    v-if="modal"
  >
    <!-- Modal  -->
    <div class="modal" v-if="modal">
      <center>
        <video
          src="./assets/img/output.mp4"
          class="face-video"
          autoplay
          loop
        ></video>
        <h3 class="text-xl dark-text font-bold mb-4">Login with...</h3>
      </center>

      <button
        @click="connectWallet('injected');"
        class="wallet-button row center centered mb-8"
      >
        <img src="./assets/img/metamask.svg" />
        Metamask
      </button>
      <button
        @click="connectWallet('walletconnect');"
        class="wallet-button row center centered"
      >
        <img src="./assets/img/walletconnect.svg" />
        WalletConnect
      </button>
      <button
        @click="connectWallet('fortmatic');"
        class="wallet-button row center centered"
      >
        <img src="./assets/img/fortmatic.svg" />
        Fortmatic
      </button>
    </div>
    <!-- Background, click to close -->
    <a @click="modal = null" class="modal-bg"></a>
  </div>

  <header class="shadow-sm lg:static lg:overflow-y-visible">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="relative flex justify-between">
        <div class="flex md:absolute md:left-0 md:inset-y-0 xl:col-span-2">
          <div class="left-header flex-shrink-0 flex items-center">
            <a href="https://oasis.so/Discord" target="_blank">
              <i class="fab fa-discord"></i>
            </a>
            <a href="https://twitter.com/oasiscalling" target="_blank">
              <i class="fab fa-twitter"></i>
            </a>
            <nav>
              <ul>
                <li>
                  <router-link to="/">Login</router-link>
                </li>
                <li v-if="environment && environment == 'development'">
                  <router-link to="/mint">Mint</router-link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div
          class="text-white relative z-0 flex-1 flex items-center justify-center md:px-8 lg:px-0 xl:col-span-7"
          style="height: 60px;"
        >
          <img src="./assets/img/logo-frame-pink.svg" class="logo-img" />
        </div>
        <!--
        <div
          class="flex items-center md:absolute md:right-0 md:inset-y-0 lg:hidden"
        >

        </div>
        -->
        <div
          class="wallet-details text-white items-center inline-block flex"
        >
          <div v-if="address">
            <!-- BALANCE -->
            <span v-if="balance">
              <img
                src="./assets/img/eth-glow.svg"
                class="eth-glow-icon inline-block"
              />
              {{balance}} ETH
            </span>
            &nbsp;

            <!-- ADDRESS -->
            <div class="address-box inline-block">
              <img src="./assets/img/logo-3d.svg" class="wallet-icon" />
              <span>{{address.substr(0,8)}}...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <br /><br />

    <div>
      <router-view
        :address="address"
        :redirect="redirect"
        :last-txn-link="lastTxnLink"
        @open-modal="openModal"
        @mint="mint"
      ></router-view>
    </div>
  </div>
</template>

<script>
import * as constants  from './constants.js';
let Web3 = window.Web3;

/* ---- WEB3MODAL: ---- */
const Web3Modal = window.Web3Modal.default;
const WalletConnectProvider = window.WalletConnectProvider.default;
const Fortmatic = window.Fortmatic;

let web3;
let web3Modal;

function init() {
  console.log("Initializing example");
  console.log("WalletConnectProvider is", WalletConnectProvider);
  console.log("Fortmatic is", Fortmatic);
  console.log(
    "window.web3 is",
    window.web3,
    "window.ethereum is",
    window.ethereum
  );

  // Tell Web3modal what providers we have available.
  // Built-in web browser provider (only one can exist as a time)
  // like MetaMask, Brave or Opera is added automatically by Web3modal
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: constants.infuraAPIKey
      }
    },
    fortmatic: {
      package: Fortmatic,
      options: {
        key: constants.fortmaticAPIKey
      }
    }
  };

  web3Modal = new Web3Modal({
    cacheProvider: false, // optional
    providerOptions, // required
    disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
    theme: "dark"
  });

  console.log("Web3Modal instance is", web3Modal);
}

/* ---- VUE APP: ---- */
export default {
  name: "App",
  components: {},
  data() {
    return {
      environment: process.env.NODE_ENV,
      constants: constants,
      address: null,
      balance: 0,
      chain: 0,
      gasPrice: 0,
      redirect: false,
      mintCount: 0,
      lastSignature: null,
      lastCode: null,
      lastWalletType: "",
      lastTxnLink: null,
      devMode: true,
      modal: false,
      merkleTree: {},
      backgrounds: {
        'bg-dark': require('@/assets/img/blackcity.jpg'),
        'bg-lit': require('@/assets/img/litcity.jpg'),
      },
      oasisContract: null
    };
  },
  computed: {
    bgImage: function() {
      return this.address ? this.backgrounds['bg-lit'] : this.backgrounds['bg-dark'];
    }
  },
  methods: {
    openModal: function() {
      this.logout();
      this.modal = true;
    },
    logout: function() {
      this.address = null;
      web3Modal.resetState();
      this.redirect = false;
      this.lastSignature = null;
      this.lastCode = null;
      this.lastWalletType = "";
      this.web3 = null;
      web3 = null;
    },
    connectWallet: function (provider) {
      this.lastWalletType = provider === "injected" ? "metamask" : provider;
      //web3Modal.connect().then((provider) => {
      web3Modal.connectTo(provider).then((returnedProvider) => {
        // Get a Web3 instance for the wallet
        web3 = new Web3(returnedProvider);
        this.web3 = web3;

        web3.eth.getGasPrice().then((gasPrice) => {
          this.gasPrice = gasPrice;
          //console.log("gasPrice = ", gasPrice);

          // Create contract object for Oasis:
          this.oasisContract = new web3.eth.Contract(constants.oasisABI, constants.oasisAddress);
          console.log("Oasis Contract Loaded = ", this.oasisContract);

          web3.eth.getAccounts().then((wallet) => {
            this.modal = false;
            console.log("Wallet = ", wallet[0]);
            this.address = wallet[0];
            this.fetchBalance();
            web3.eth.getChainId().then((chain) => {
              this.chain = chain;
              //console.log("Chain ID = ", chain);

              if(this.$route.fullPath.substr(0, 5) != "/mint") {
                let postData1 = { wallet: this.address };
                console.log(
                  "1. Sending API request to /register...\nPOST Data = ",
                  postData1
                );
                // Fetch the message we want to sign:
                let domain = process.env.VUE_APP_API_URL;
                console.log("API URL = ", domain);
                fetch(domain + "/api/v1/account/register", {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                  },
                  body: JSON.stringify(postData1)
                })
                  .then((response) => response.json())
                  .then((data) => {
                    let code = data.code;
                    this.lastCode = code;
                    let message = data.message;
                    console.log(
                      "Returned Data:\n- code = " +
                        code +
                        "\n- message = " +
                        message
                    );

                    console.log("2. Signing message: " + message + code);
                    web3.eth.personal
                      .sign(message + code, this.address, "")
                      .then((signature) => {
                        console.log("Returned signature:\n", signature);

                        this.redirect = true;
                        this.lastSignature = signature;
                        this.redirectNow();
                      })
                      .catch((error) => {
                        console.log("ERROR!", error);
                        // Send user back to first screen:
                        this.logout();
                      });
                  });
              }
            });
          });
        });
      });
    },
    fetchBalance: function () {
      console.log("fetching balance for ", this.address);
      this.web3.eth.getBalance(this.address).then((balance) => {
        let convertedBalance = web3.utils.fromWei(balance, "ether");
        const humanFriendlyBalance = parseFloat(convertedBalance).toFixed(2);
        this.balance = humanFriendlyBalance;
        console.log("Balance = ", this.balance);
      });
    },
    selectNFTs: function () {
      this.mintCount = 1;
    },
    mint: function (mintCount) {
      let proof = this.merkleTree[this.address] ? this.merkleTree[this.address].proof : [];
      console.log("Now minting...", proof);
      this.lastTxnLink = null;
      this.redirect = false;
      let amountWei = Number(constants.tokenPriceInWei * this.mintCount).toString();

      this.oasisContract.methods
        .mint(mintCount, proof)
        .estimateGas({
          value: amountWei,
          from: this.address,
          gasPrice: this.gasPrice
        })
        .then((gasLimit) => {
          gasLimit = Number(gasLimit);

          this.oasisContract.methods
            .mint(mintCount, proof)
            .send({
              value: amountWei,
              from: this.address,
              gasPrice: this.gasPrice,
              gasLimit: gasLimit
            })
            .on("transactionHash", (hash) => {
              let hashlink =
                this.chain !== 1
                  ? "https://rinkeby.etherscan.io/tx/"
                  : "https://etherscan.io/tx/";
              hashlink += hash;
              console.log("TX Created! ", hashlink);
              this.lastTxnLink = hashlink;
            })
            .on("receipt", (receipt) => {
              console.log("*** TX Completed! ", receipt);
              this.redirect = true;
            });
        });
    },
    redirectNow: function () {
      let redirectLink =
        "oasismac://walletsignin?wallettype=" +
        this.lastWalletType +
        "&walletid=" +
        this.address +
        "&signature=" +
        this.lastSignature;
      console.log("Now redirecting to...", redirectLink);
      window.location.href = redirectLink; // TODO: Add transaction hash here?
    }
  },
  mounted() {
    init();
    this.openModal();

    // Fetch merkle JSON proofs:
    fetch('merkle_tree.json', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.json())
    .then(res => {
      this.merkleTree = res;
    });

  }
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  margin: 0;
  padding: 0;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-y: bottom;
  height: 100%;
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  /* flex-direction: column; */
}
.router-link-active {
  border-bottom: 1px solid #b12faa;
}
footer {
  position: sticky;
  top: 99%;
  bottom: 0;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
  text-decoration: none;
}
</style>
