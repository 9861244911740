import { createApp } from "vue";
import App from "./App.vue";
import Login from "./components/Login";
import Mint from "./components/Mint";
import { createRouter, createWebHistory } from "vue-router";

import '../public/css/style.css';


const routerHistory = createWebHistory();

const router = createRouter({
  history: routerHistory,
  // history: createWebHistory(),
  routes: [
    {
      path: "/mint",
      component: Mint
    },
    {
      path: "/",
      component: Login
    }
  ]
});
const app = createApp(App);
app.use(router);

app.mount("#app");
// createApp(App).mount('#app')
