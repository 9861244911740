<template>
  <div class="container gridbox" v-if="true">
    <br />
    <!--
    <div class="custom-number-input h-10 w-40 mx-auto">
      <div class="flex flex-row h-14 w-full rounded-lg relative bg-transparent mt-1">
        <button @click="decrement()" class=" bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none">
          <span class="m-auto text-2xl font-thin">−</span>
        </button>
        <input type="number" v-model="mintCount" class="text-xl outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none" name="custom-input-number" />
      <button @click="increment()" class="bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer">
        <span class="m-auto text-2xl font-thin">+</span>
       </button>
      </div>
    </div>
  ``-->

    <div v-if="address && !lastTxnLink">
      <center><b class="text-xl">Click the button below to mint your NFT:</b></center>
      <button @click="mintNow()" class="button-blue bottom-button block" style="margin-top: 40px;">
        Mint 1 NFT
      </button>
    </div>

    <div v-if="address && lastTxnLink && !redirect" class="center">
      <img
        src="@/assets/img/spinner.gif"
        style="width: 36%; margin: -120px auto -120px auto;"
      />
      <div>Your transaction is now pending... <a :href="lastTxnLink" target="_blank" class="violet-a">View your transaction</a></div>
    </div>

    <div v-else-if="address && lastTxnLink && redirect" class="center">
      <b class="text-xl">You transaction completed successfully!</b>
      <div>You can now view it on the blockchain. <a :href="lastTxnLink" target="_blank" class="violet-a">View your transaction</a></div>
      <div
        class="button-blue"
        @click="openApp()"
        style="margin: 40px auto;"
      >
        Launch Oasis
      </div>
      <p>
        Don't have Oasis installed?
        <a href="https://theoasis.com/desktop" class="violet-a" target="_blank"
          >Download Now</a
        >
      </p>
    </div>

    <div v-else-if="!address">
      <center><b class="text-xl">Connect your wallet to get started:</b></center>
      <button @click="$emit('open-modal')" class="button-blue bottom-button block" style="margin-top: 40px;">
        Connect Wallet
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Mint",
  data() {
    return {
    mintCount: 1
    }
  },
  props: {
    address: String,
    lastTxnLink: String,
    redirect: Boolean
  },
  methods: {
    increment: function () {
      if (this.mintCount < 10) {
        this.mintCount++;
      }
    },
    decrement: function () {
      if (this.mintCount > 1) {
        this.mintCount--;
      }
    },
    mintNow: function () {
      this.$emit('mint', this.mintCount);
    },
    openApp: function () {
      let redirectLink = "oasismac://walletsignin";
      window.location.href = redirectLink;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
