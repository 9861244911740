<template>
  <div>
    <div v-if="!address">
      <br />
      <div class="button-blue" @click="$emit('open-modal')">
        Connect Wallet
      </div>
    </div>

    <div class="center" v-if="address && !redirect">
      <br />
      <p class="mt-4 mb-10 text-white">
        Click "Sign" to sign in.<br />This request will not trigger a
        blockchain transaction or cost any gas fees.
      </p>
      <img
        src="@/assets/img/spinner.gif"
        style="width: 36%; margin: -120px auto 0 auto;"
      />
    </div>

    <div v-else-if="redirect" class="redirect-info">
      <div class="redirect-header">
        Click <b>Open Oasis</b> on the dialog shown by your browser.<br />If
        you don't see a dialog, click <b>Launch Oasis</b> below
      </div>
      <div
        class="button-blue"
        @click="redirectNow()"
        style="margin: 40px auto;"
      >
        Launch Oasis
      </div>
      <p>
        Don't have Oasis installed?
        <a href="https://theoasis.com/desktop" target="_blank"
          >Download Now</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  components: {},
  props: {
    address: String,
    redirect: Boolean
  },
  data() {
    return {
    }
  },
  mounted() {
  }
};

</script>
